//
//
//
//
//
//
//
//
//

import { copyrightInfoApi } from '@/api/authInformation';
import { checkPermi } from '@/utils/permission'; // 权限判断函数
export default {
  name: 'i-copyright',
  data: function data() {
    return {
      links: [{
        title: '官网',
        key: '1',
        href: 'https://www.crmeb.com',
        blankTarget: true
      }, {
        title: '社区',
        key: '2',
        href: 'https://q.crmeb.net/?categoryId=122&sequence=0',
        blankTarget: true
      }, {
        title: '文档',
        key: '3',
        href: 'https://help.crmeb.net/crmeb_java/1748037',
        blankTarget: true
      }],
      copyright: 'Copyright © 2023 西安众邦网络科技有限公司',
      copyrightNew: ''
    };
  },
  mounted: function mounted() {
    if (checkPermi(['platform:copyright:get:info'])) this.getVersion();
  },
  methods: {
    getVersion: function getVersion() {
      var _this = this;
      copyrightInfoApi().then(function (res) {
        var data = res || {};
        _this.copyrightNew = data.companyName ? data.companyName : _this.copyright;
      });
    }
  }
};