import Layout from '@/layout';
var designRouter = {
  path: '/design',
  component: Layout,
  redirect: '/design/index',
  name: 'design',
  meta: {
    title: '装修',
    icon: 'clipboard'
  },
  children: [{
    path: 'theme',
    name: 'theme',
    component: function component() {
      return import('@/views/design/theme/index');
    },
    meta: {
      title: '一键换色'
    }
  }, {
    path: 'viewDesign',
    name: 'viewDesign',
    component: function component() {
      return import('@/views/design/viewDesign/index');
    },
    meta: {
      title: '页面设计'
    }
  }]
};
export default designRouter;